<template>
  <router-view />
</template>

<script>
import installationModule from '../../store/installations'
import financePackageModule from '../../store/finance-packages'
import teamModule from '../../store/teams'
import vanModule from '../../store/vans'
import ticketModule from '../../store/tickets'
import eventModule from '../../store/events'

export default {
  beforeCreate () {
    this.$store.registerModule('installations', installationModule)
    this.$store.registerModule('finance-packages', financePackageModule)
    this.$store.registerModule('teams', teamModule)
    this.$store.registerModule('vans', vanModule)
    this.$store.registerModule('tickets', ticketModule)
    this.$store.registerModule('events', eventModule)
  },
  destroyed () {
    this.$store.unregisterModule('installations')
    this.$store.unregisterModule('finance-packages')
    this.$store.unregisterModule('teams')
    this.$store.unregisterModule('vans')
    this.$store.unregisterModule('tickets')
    this.$store.unregisterModule('events')
  }
}
</script>
