<template>
  <b-container fluid>
    <b-form @submit.prevent="submitSignOff">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary mb-0">Installation</h1>
          <small class="text-danger" v-if="errors.errors">There are errors on this page.</small>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col cols="12">
                    <div class="d-flex flex-column">
                      <p class="h3 font-weight-bold mb-0">{{ installation.order.customer.forename }} {{ installation.order.customer.surname }}</p>
                      <small class="text-muted">{{ installation.order.customer.email }}</small>
                      <small class="mb-0 text-danger" v-if="installation.order.customer.deleted_at"><small>Customer Trashed.</small></small>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col cols="12">
                    <b-row>
                      <b-col cols="12" md="6">
                        <b-card>
                          <b-row>
                            <b-col cols="12">
                              <p class="h4 font-weight-bold mb-0">Shipping &amp; Installation Address</p>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12">
                              <div><hr></div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12">
                              <b-form-group label="Address Line 1" :invalid-feedback="validationInvalidFeedback(errors.errors, 'shipping_address.address_line_1')">
                                <b-form-input :disabled="true" :state="validationState(errors, 'shipping_address.address_line_1')" v-model="installation.order.shipping_address.address_line_1" />
                              </b-form-group>
                            </b-col>
                            <b-col cols="12">
                              <b-form-group label="Address Line 2" :invalid-feedback="validationInvalidFeedback(errors.errors, 'shipping_address.address_line_2')">
                                <b-form-input :disabled="true" :state="validationState(errors, 'shipping_address.address_line_2')" v-model="installation.order.shipping_address.address_line_2" />
                              </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                              <b-form-group label="City" :invalid-feedback="validationInvalidFeedback(errors.errors, 'shipping_address.city')">
                                <b-form-input :disabled="true" :state="validationState(errors, 'shipping_address.city')" v-model="installation.order.shipping_address.city" />
                              </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                              <b-form-group label="County" :invalid-feedback="validationInvalidFeedback(errors.errors, 'shipping_address.county')">
                                <b-form-input :disabled="true" :state="validationState(errors, 'shipping_address.county')" v-model="installation.order.shipping_address.county" />
                              </b-form-group>
                            </b-col>
                            <b-col cols="12">
                              <b-form-group label="Postcode" :invalid-feedback="validationInvalidFeedback(errors.errors, 'shipping_address.postcode')">
                                <b-form-input :disabled="true" :state="validationState(errors, 'shipping_address.postcode')" v-model="installation.order.shipping_address.postcode" />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-col>
                      <b-col cols="12" md="6" class="mt-3 mt-md-0">
                        <b-card>
                          <b-row>
                            <b-col cols="12">
                              <p class="h4 font-weight-bold mb-0">Billing Address</p>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12">
                              <div><hr></div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12">
                              <b-form-group label="Address Line 1" :invalid-feedback="validationInvalidFeedback(errors.errors, 'billing_address.address_line_1')">
                                <b-form-input :disabled="true" :state="validationState(errors, 'billing_address.address_line_1')" v-model="installation.order.billing_address.address_line_1" />
                              </b-form-group>
                            </b-col>
                            <b-col cols="12">
                              <b-form-group label="Address Line 2" :invalid-feedback="validationInvalidFeedback(errors.errors, 'address.address_line_2')">
                                <b-form-input :disabled="true" :state="validationState(errors, 'billing_address.address_line_2')" v-model="installation.order.billing_address.address_line_2" />
                              </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                              <b-form-group label="City" :invalid-feedback="validationInvalidFeedback(errors.errors, 'billing_address.city')">
                                <b-form-input :disabled="true" :state="validationState(errors, 'billing_address.city')" v-model="installation.order.billing_address.city" />
                              </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                              <b-form-group label="County" :invalid-feedback="validationInvalidFeedback(errors.errors, 'billing_address.county')">
                                <b-form-input :disabled="true" :state="validationState(errors, 'billing_address.county')" v-model="installation.order.billing_address.county" />
                              </b-form-group>
                            </b-col>
                            <b-col cols="12">
                              <b-form-group label="Postcode" :invalid-feedback="validationInvalidFeedback(errors.errors, 'billing_address.postcode')">
                                <b-form-input :disabled="true" :state="validationState(errors, 'billing_address.postcode')" v-model="installation.order.billing_address.postcode" />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" @click.prevent="toggleCollapse('video_route')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['video_route'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Video Route</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['video_route']">
                  <b-col cols="12" v-if="installation.video">
                    <b-row>
                      <b-col cols="12">
                        <hr class="mt-0">
                      </b-col>
                    </b-row>
                    <b-row v-if="installation.route_comments">
                      <b-col cols="12">
                        <b-form-group label="Route Comments">
                          <b-form-textarea v-model="installation.route_comments" disabled></b-form-textarea>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <video class="w-100" :src="installation.video.url" controls></video>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" v-if="!installation.video" class="p-4">
                    <b-row>
                      <b-col class="text-center" cols="12">
                        <b-card class="py-3 text-center">
                          <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                          <p class="font-weight-normal h5 mb-0 text-dark">The customer has not uploaded a video.</p>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="9" @click.prevent="toggleCollapse('products_appointment')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['products_appointment'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Installation Item</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['products_appointment']">
                  <b-col cols="12" class="p-4">
                    <b-row>
                      <b-col cols="12">
                        <b-card class="mb-3">
                          <b-row>
                            <b-col cols="7">
                              <h4 class="mb-0">Product</h4>
                              <p class="mb-0">{{ installation.product.name }}</p>
                            </b-col>
                            <b-col cols="5" class="text-right">
                              <h5 class="mb-0">Supply: {{ installation.supply_product.supply.name }}</h5>
                              <p class="mb-0" v-if="installation.supply_product.storage_location_id">{{ installation.supply_product.storage_location.name }}</p>
                              <small>Expected Date: {{ installation.supply_product.expected_date ? moment(installation.supply_product.expected_date).format('DD/MM/YYYY') : moment(installation.supply_product.supply.expected_date).format('DD/MM/YYYY') }}</small>
                            </b-col>
                          </b-row>
                          <b-row v-if="installation.extras.length">
                            <b-col cols="12">
                              <div class="d-flex flex-column mt-3" v-if="installation.extras.length">
                                <b-card>
                                  <h6 class="mb-3">Extras:</h6>
                                  <b-table-simple small striped>
                                    <b-thead>
                                      <b-tr>
                                        <b-th>Name</b-th>
                                      </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                      <b-tr v-for="(extra, extraInd) in installation.extras" :key="`extra${installation.id}-${extraInd}`">
                                        <b-td>{{ extra.name }}</b-td>
                                      </b-tr>
                                    </b-tbody>
                                  </b-table-simple>
                                </b-card>
                              </div>
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label="Install Required">
                          <b-form-radio :disabled="true" v-model="installation.install_required" :value="1">Install is Required</b-form-radio>
                          <b-form-radio :disabled="true" v-model="installation.install_required" :value="0">Install is not Required</b-form-radio>
                        </b-form-group>
                        <b-form-group label="Install At" v-if="installation.install_required">
                          <b-form-datepicker :disabled="true" v-model="installation.install_at" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="6">
                        <b-form-group label="Crane Required" v-if="installation.install_required">
                          <b-form-radio :disabled="true" v-model="installation.crane_required" :value="1">Crane is Required</b-form-radio>
                          <b-form-radio :disabled="true" v-model="installation.crane_required" :value="0">Crane is not Required</b-form-radio>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Electrics Required" v-if="installation.install_required">
                          <b-form-radio :disabled="true" v-model="installation.electrics_required" :value="1">Electrics is Required</b-form-radio>
                          <b-form-radio :disabled="true" v-model="installation.electrics_required" :value="0">Electrics is not Required</b-form-radio>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="9" @click.prevent="toggleCollapse('installation_notes')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['installation_notes'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Installation Notes</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['installation_notes']">
                  <b-col cols="12">
                    <hr class="mt-0">
                  </b-col>
                  <b-col cols="12">
                    <b-card v-for="note in installation.notes" :key="`note-${note.id}`" class="mb-2">
                      <b-row>
                        <b-col cols="12">
                          {{ note.note }}
                        </b-col>
                        <b-col cols="12">
                          <hr>
                        </b-col>
                        <b-col cols="12">
                          <div class="d-flex flex-row">
                            <p class="text-muted flex-fill mb-0">Left by: {{ note.user.name }}</p>
                            <p class="text-muted mb-0">{{ moment(note.created_at).format('DD/MM/YYYY') }}</p>
                          </div>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <b-form-textarea placeholder="Enter a note" v-model="installationNote"></b-form-textarea>
                    </b-form-group>
                    <b-button variant="primary" @click="submitNote">Add Note</b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="9" @click.prevent="toggleCollapse('sign_off')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['sign_off'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Installation Sign Off</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['sign_off']">
                  <b-col cols="12" class="p-4">
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label="SPA Serial Number" :invalid-feedback="validationInvalidFeedback(errors.errors, 'meta.spa_serial_number')">
                          <b-form-input :disabled="isSaving" v-model="signOff.meta.spa_serial_number" :state="validationState(errors.errors, 'meta.spa_serial_number')"/>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="6">
                        <b-form-group label="Motherboard in Situ">
                          <b-form-checkbox :disabled="isSaving" v-model="signOff.meta.motherboard_in_situ" :value="1">Motherboard in situ</b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Motherboard Serial Number" :invalid-feedback="validationInvalidFeedback(errors.errors, 'meta.motherboard_serial_number')">
                          <b-form-input v-model="signOff.meta.motherboard_serial_number" :disabled="isSaving" :state="validationState(errors.errors, 'meta.motherboard_serial_number')"/>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-img :src="returnFileUrl('spa_serial_number_photo')" fluid block></b-img>
                        <b-form-group label="Photo Of Spa Serial Number" :invalid-feedback="validationInvalidFeedback(errors.errors, 'uploads.spa_serial_number_photo')">
                            <b-form-file
                              :disabled="isSaving"
                              v-model="signOff.uploads.spa_serial_number_photo"
                              :state="Boolean(signOff.uploads.spa_serial_number_photo)"
                              :placeholder="signOff.uploads.spa_serial_number_photo ? signOff.uploads.spa_serial_number_photo.name : 'Choose a file or drop it here...'"
                              drop-placeholder="Drop file here..."
                            ></b-form-file>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-img :src="returnFileUrl('electrical_connection')" fluid block></b-img>
                        <b-form-group label="Photo Of Electrical Connection Showing Internal Terminals Screwed Down" :invalid-feedback="validationInvalidFeedback(errors.errors, 'uploads.electrical_connection')">
                            <b-form-file
                              :disabled="isSaving"
                              v-model="signOff.uploads.electrical_connection"
                              :state="Boolean(signOff.uploads.electrical_connection)"
                              :placeholder="signOff.uploads.electrical_connection ? signOff.uploads.electrical_connection.name : 'Choose a file or drop it here...'"
                              drop-placeholder="Drop file here..."
                            ></b-form-file>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-img :src="returnFileUrl('cable_entry')" fluid block></b-img>
                        <b-form-group label="Photo Of Cable Entry" :invalid-feedback="validationInvalidFeedback(errors.errors, 'uploads.cable_entry')">
                            <b-form-file
                              :disabled="isSaving"
                              v-model="signOff.uploads.cable_entry"
                              :state="Boolean(signOff.uploads.cable_entry)"
                              :placeholder="signOff.uploads.cable_entry ? signOff.uploads.cable_entry.name : 'Choose a file or drop it here...'"
                              drop-placeholder="Drop file here..."
                            ></b-form-file>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-img :src="returnFileUrl('side_panel_bolts')" fluid block></b-img>
                        <b-form-group label="Photo of bolts installed correctly on side panels" :invalid-feedback="validationInvalidFeedback(errors.errors, 'uploads.side_panel_bolts')">
                            <b-form-file
                              :disabled="isSaving"
                              v-model="signOff.uploads.side_panel_bolts"
                              :state="Boolean(signOff.uploads.side_panel_bolts)"
                              :placeholder="signOff.uploads.side_panel_bolts ? signOff.uploads.side_panel_bolts.name : 'Choose a file or drop it here...'"
                              drop-placeholder="Drop file here..."
                            ></b-form-file>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-img :src="returnFileUrl('cover_lifter_fitting')" fluid block></b-img>
                        <b-form-group label="Photo of both side of the cover lifter fitting" :invalid-feedback="validationInvalidFeedback(errors.errors, 'uploads.cover_lifter_fitting')">
                            <b-form-file
                              :disabled="isSaving"
                              v-model="signOff.uploads.cover_lifter_fitting"
                              :state="Boolean(signOff.uploads.cover_lifter_fitting)"
                              :placeholder="signOff.uploads.cover_lifter_fitting ? signOff.uploads.cover_lifter_fitting.name : 'Choose a file or drop it here...'"
                              drop-placeholder="Drop file here..."
                            ></b-form-file>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-img :src="returnFileUrl('cover_defects')" fluid block></b-img>
                        <b-form-group label="Photo of the cover for any defects" :invalid-feedback="validationInvalidFeedback(errors.errors, 'uploads.cover_defects')">
                            <b-form-file
                              :disabled="isSaving"
                              v-model="signOff.uploads.cover_defects"
                              :state="Boolean(signOff.uploads.cover_defects)"
                              :placeholder="signOff.uploads.cover_defects ? signOff.uploads.cover_defects.name : 'Choose a file or drop it here...'"
                              drop-placeholder="Drop file here..."
                            ></b-form-file>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-img :src="returnFileUrl('isolator_wiring_internals')" fluid block></b-img>
                        <b-form-group label="Photo of internals of isolator wiring" :invalid-feedback="validationInvalidFeedback(errors.errors, 'uploads.isolator_wiring_internals')">
                            <b-form-file
                              :disabled="isSaving"
                              v-model="signOff.uploads.isolator_wiring_internals"
                              :state="Boolean(signOff.uploads.isolator_wiring_internals)"
                              :placeholder="signOff.uploads.isolator_wiring_internals ? signOff.uploads.isolator_wiring_internals.name : 'Choose a file or drop it here...'"
                              drop-placeholder="Drop file here..."
                            ></b-form-file>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-img :src="returnFileUrl('spa_in_place')" fluid block></b-img>
                        <b-form-group label="Photo of spa in place" :invalid-feedback="validationInvalidFeedback(errors.errors, 'uploads.spa_in_place')">
                            <b-form-file
                              :disabled="isSaving"
                              v-model="signOff.uploads.spa_in_place"
                              :state="Boolean(signOff.uploads.spa_in_place)"
                              :placeholder="signOff.uploads.spa_in_place ? signOff.uploads.spa_in_place.name : 'Choose a file or drop it here...'"
                              drop-placeholder="Drop file here..."
                            ></b-form-file>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-row class="mb-4">
                          <b-col cols="12">
                            <b-card>
                              <b-row>
                                <b-col class="d-flex flex-row" cols="12" @click.prevent="toggleCollapse('access_run')" role="button">
                                  <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['access_run'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                                  <div class="d-flex flex-column flex-fill ml-4">
                                    <p class="h4 font-weight-bold mb-0" :class="{ 'text-danger': computedAccessRunHasErrors }">Photos of the access run please upload as many as possible</p>
                                  </div>
                                  <fa-icon class="text-danger" icon="flag" v-if="computedAccessRunHasErrors"></fa-icon>
                                </b-col>
                              </b-row>
                              <b-row v-if="!collapsed['access_run']">
                                <b-col cols="12" class="mt-4" v-if="!signOff.uploads.access_run.length">
                                  <b-row>
                                    <b-col class="text-center" cols="12">
                                      <b-card class="py-3 text-center">
                                        <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                                        <p class="font-weight-normal h5 mb-0 text-dark">There are no images.</p>
                                      </b-card>
                                    </b-col>
                                  </b-row>
                                </b-col>
                                <b-col cols="12" v-if="signOff.uploads.access_run.length">
                                  <hr>
                                  <b-row v-for="(img, imgInd) in signOff.uploads.access_run" :key="imgInd">
                                    <b-col cols="12">
                                      <b-img :src="returnFileUrl('access_run['+imgInd+']')" fluid block></b-img>
                                      <b-form-file
                                        class="mb-2"
                                        :disabled="isSaving"
                                        v-model="signOff.uploads.access_run[imgInd]"
                                        :state="Boolean(signOff.uploads.access_run[imgInd])"
                                        :placeholder="signOff.uploads.access_run[imgInd] ? signOff.uploads.access_run[imgInd].name : 'Choose a file or drop it here...'"
                                        drop-placeholder="Drop file here..."
                                      ></b-form-file>
                                    </b-col>
                                  </b-row>
                                </b-col>
                                <b-col cols="12">
                                  <b-button class="mt-3 float-right" @click="addImageTo('access_run')">Add Access Run Image</b-button>
                                </b-col>
                              </b-row>
                            </b-card>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-img :src="returnFileUrl('electrical_installation_certificate')" fluid block></b-img>
                        <b-form-group label="Photo of electrical installation certificate" :invalid-feedback="validationInvalidFeedback(errors.errors, 'uploads.electrical_installation_certificate')">
                            <b-form-file
                              :disabled="isSaving"
                              v-model="signOff.uploads.electrical_installation_certificate"
                              :state="Boolean(signOff.uploads.electrical_installation_certificate)"
                              :placeholder="signOff.uploads.electrical_installation_certificate ? signOff.uploads.electrical_installation_certificate.name : 'Choose a file or drop it here...'"
                              drop-placeholder="Drop file here..."
                            ></b-form-file>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-row class="mb-4">
                          <b-col cols="12">
                            <b-card>
                              <b-row>
                                <b-col class="d-flex flex-row" cols="12" @click.prevent="toggleCollapse('defects')" role="button">
                                  <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['defects'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                                  <div class="d-flex flex-column flex-fill ml-4">
                                    <p class="h4 font-weight-bold mb-0" :class="{ 'text-danger': computedDefectsHasErrors }">Photos of any defects</p>
                                  </div>
                                  <fa-icon class="text-danger" icon="flag" v-if="computedDefectsHasErrors"></fa-icon>
                                </b-col>
                              </b-row>
                              <b-row v-if="!collapsed['defects']">
                                <b-col cols="12" class="mt-4" v-if="!signOff.uploads.defects.length">
                                  <b-row>
                                    <b-col class="text-center" cols="12">
                                      <b-card class="py-3 text-center">
                                        <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                                        <p class="font-weight-normal h5 mb-0 text-dark">There are no images.</p>
                                      </b-card>
                                    </b-col>
                                  </b-row>
                                </b-col>
                                <b-col cols="12" v-if="signOff.uploads.defects.length">
                                  <hr>
                                  <b-row v-for="(img, imgInd) in signOff.uploads.defects" :key="imgInd">
                                    <b-col cols="12">
                                      <b-img :src="returnFileUrl('defects['+imgInd+']')" fluid block></b-img>
                                      <b-form-file
                                        class="mb-2"
                                        :disabled="isSaving"
                                        v-model="signOff.uploads.defects[imgInd]"
                                        :state="Boolean(signOff.uploads.defects[imgInd])"
                                        :placeholder="signOff.uploads.defects[imgInd] ? signOff.uploads.defects[imgInd].name : 'Choose a file or drop it here...'"
                                        drop-placeholder="Drop file here..."
                                      ></b-form-file>
                                    </b-col>
                                  </b-row>
                                </b-col>
                                <b-col cols="12">
                                  <b-button class="mt-3 float-right" @click="addImageTo('defects')">Add Defect Image</b-button>
                                </b-col>
                              </b-row>
                            </b-card>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-row>
                          <b-col cols="12">
                            <b-card>
                              <b-row>
                                <b-col class="d-flex flex-row" cols="12" @click.prevent="toggleCollapse('notable_issues')" role="button">
                                  <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['notable_issues'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                                  <div class="d-flex flex-column flex-fill ml-4">
                                    <p class="h4 font-weight-bold mb-0">Raise a ticket</p>
                                    <small class="text-muted mt-1">If there were any issues during installation, add these here to automatically generate a ticket.</small>
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row v-if="!collapsed['notable_issues']">
                                <b-col cols="12" class="mt-4">
                                  <b-row>
                                    <b-col cols="12" md="12">
                                      <b-form-group label="Description of the Fault" :invalid-feedback="validationInvalidFeedback(errors.errors, 'ticket.description')">
                                        <b-form-textarea :disabled="isSaving" :state="validationState(errors.errors, 'ticket.description')" v-model="ticket.description" />
                                      </b-form-group>
                                    </b-col>
                                  </b-row>
                                  <b-row class="mb-4">
                                    <b-col cols="12">
                                      <b-card>
                                        <b-row>
                                          <b-col class="d-flex flex-row" cols="12" md="9" @click.prevent="toggleCollapse('issue_images')" role="button">
                                            <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['issue_images'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                                            <div class="d-flex flex-column flex-fill ml-4">
                                              <p class="h4 font-weight-bold mb-0">Issue Images</p>
                                            </div>
                                          </b-col>
                                        </b-row>
                                        <b-row class="mt-4" v-if="!collapsed['issue_images']">
                                          <b-col cols="12" md="12">
                                            <b-img :src="returnTicketFileUrl('serial_number_image')" fluid block></b-img>
                                            <b-form-group label="Hot Tub Serial Number" :invalid-feedback="validationInvalidFeedback(errors.errors, 'serial_number_image')">
                                                <b-form-file
                                                  v-model="ticket.serial_number_image"
                                                  :state="Boolean(ticket.serial_number_image)"
                                                  :placeholder="ticket.serial_number_image ? ticket.serial_number_image.name : 'Choose a file or drop it here...'"
                                                  drop-placeholder="Drop file here..."
                                                ></b-form-file>
                                            </b-form-group>
                                          </b-col>
                                          <b-col cols="12" md="12">
                                            <b-img :src="returnTicketFileUrl('hot_tub_image')" fluid block></b-img>
                                            <b-form-group label="Hot Tub Photo" :invalid-feedback="validationInvalidFeedback(errors.errors, 'hot_tub_image')">
                                                <b-form-file
                                                  v-model="ticket.hot_tub_image"
                                                  :state="Boolean(ticket.hot_tub_image)"
                                                  :placeholder="ticket.hot_tub_image ? ticket.hot_tub_image.name : 'Choose a file or drop it here...'"
                                                  drop-placeholder="Drop file here..."
                                                ></b-form-file>
                                            </b-form-group>
                                          </b-col>
                                          <b-col cols="12" md="12">
                                            <b-img :src="returnTicketFileUrl('problem_file')" fluid block></b-img>
                                            <b-form-group label="Problem Photo" :invalid-feedback="validationInvalidFeedback(errors.errors, 'problem_file')">
                                                <b-form-file
                                                  v-model="ticket.problem_file"
                                                  :state="Boolean(ticket.problem_file)"
                                                  :placeholder="ticket.problem_file ? ticket.problem_file.name : 'Choose a file or drop it here...'"
                                                  drop-placeholder="Drop file here..."
                                                ></b-form-file>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </b-card>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col cols="12">
                                      <b-form-group  :invalid-feedback="validationInvalidFeedback(errors.errors, 'accept_disclaimer')">
                                        <b-form-checkbox
                                          id="accept-disclaimer"
                                          name="accept-disclaimer"
                                          v-model="ticket.accept_disclaimer"
                                          :value="1"
                                          :unchecked-value="0">
                                          Accept Disclaimer
                                        </b-form-checkbox>
                                      </b-form-group>
                                    </b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                            </b-card>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="installation.order.additional_notes">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12">
                    <div class="d-flex flex-column flex-fill justify-content-center">
                      <p class="h4 font-weight-bold">Additional Notes</p>
                      <p class="mb-0 text-muted white-space-pre">{{ installation.order.additional_notes }}</p>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12" md="4" offset-md="8">
              <b-button block type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import validation from '../../mixins/validation'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'
import get from 'lodash/get'
import has from 'lodash/has'

export default {
  mixins: [validation,momentMixin],
  created () {
    var self = this;
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id)
    }
    this.fetchStatuses().then(response => {
      self.statuses = response.data;
    });
  },
  computed: {
    ...mapGetters('installations', ['errors', 'isLoading', 'isSaving', 'single']),
    computedHasTicket() {
      return (
        this.ticket.name ||
        this.ticket.email ||
        this.ticket.phone ||
        this.ticket.spa ||
        this.ticket.status ||
        this.ticket.description ||
        this.ticket.serial_number_image ||
        this.ticket.hot_tub_image ||
        this.ticket.problem_file
      );
    },

    computedAccessRunHasErrors() {
      if(this.errors.hasOwnProperty('errors')) {
        return Boolean(Object.keys(this.errors.errors).filter(err => {
          return Boolean(err.startsWith('uploads.access_run'));
        }))
      }
      return false
    },

    computedDefectsHasErrors() {
      if(this.errors.hasOwnProperty('errors')) {
        return Boolean(Object.keys(this.errors.errors).filter(err => {
          return Boolean(err.startsWith('uploads.defects'));
        }))
      }
      return false
    }
  },
  data () {
    return {
      collapsed: {
        installation_details: true,
        billing_address: true,
        shipping_address: true,
        products_appointment: true,
        sign_off: false,
        access_run: true,
        defects: true,
        notable_issues: true,
        issue_images: true,
        video_route: true,
        installation_notes: false
      },
      installation: {
        id: null,
        install_at: null,
        deposit_amount: null,
        crane_required: null,
        electrics_required: null,
        notes: [],
        billing_address: {},
        shipping_address: {},
        items: [],
        finance_package_id: null,
        customer: null,
      },
      financePackages: [],

      signOff: {
        id: null,
        meta: {
          spa_serial_number: null,
          motherboard_in_situ: null,
          motherboard_serial_number: null,
        },
        uploads: {

          // Photo of spa serial number
          spa_serial_number_photo: null,

          // Photo Of Electrical Connection Showing Internal Terminals Screwed Down
          electrical_connection: null,
          
          // Photo Of Cable entry
          cable_entry: null,

          // Photo of Bolts installed correctly on side panels
          side_panel_bolts: null,

          // Photo of both side of the cover lifter fitting
          cover_lifter_fitting: null,

          // Photo of the cover for any defects
          cover_defects: null,

          // Photo of internals of isolator wiring
          isolator_wiring_internals: null,

          // Photo of spa in place
          spa_in_place: null,

          // Photo of the access run please upload as many as possible
          access_run: [],

          // Photo of electrical installation certificate
          electrical_installation_certificate: null,

          // Photos of any defects
          defects: [],

        },
      },

      installationNote: null,

      statuses: [],

      ticket: {
        // name: null,
        // email: null,
        // phone: null,
        // spa: null,
        // status: null,
        description: null,
        serial_number_image: null,
        hot_tub_image: null,
        problem_file: null,
        accept_disclaimer: false,
      }
    }
  },
  methods: {

    ...mapActions('installations', ['fetch', 'doSignOff', 'doCreateInstallationNote']),


    ...mapActions('tickets', ['fetchStatuses']),

    toggleCollapse (name) {
      this.collapsed[name] = !this.collapsed[name]
    },

    submitSignOff () {
      let formData = new FormData();
      formData.append('meta[spa_serial_number]', this.signOff.meta.spa_serial_number ?? '');

      formData.append('meta[motherboard_in_situ]', Boolean(this.signOff.meta.motherboard_in_situ));
      formData.append('meta[motherboard_serial_number]', this.signOff.meta.motherboard_serial_number ?? '');
      
      formData.append('uploads[spa_serial_number_photo]', this.signOff.uploads.spa_serial_number_photo)
      formData.append('uploads[electrical_connection]', this.signOff.uploads.electrical_connection)
      formData.append('uploads[cable_entry]', this.signOff.uploads.cable_entry)
      formData.append('uploads[side_panel_bolts]', this.signOff.uploads.side_panel_bolts)
      formData.append('uploads[cover_lifter_fitting]', this.signOff.uploads.cover_lifter_fitting)
      formData.append('uploads[cover_defects]', this.signOff.uploads.cover_defects)
      formData.append('uploads[isolator_wiring_internals]', this.signOff.uploads.isolator_wiring_internals)
      formData.append('uploads[spa_in_place]', this.signOff.uploads.spa_in_place)
      formData.append('uploads[electrical_installation_certificate]', this.signOff.uploads.electrical_installation_certificate)

      // Access Run Images:
      this.signOff.uploads.access_run.forEach((imageObj, imageInd) => {
        formData.append(`uploads[access_run][${imageInd}]`, imageObj)
      })

      // Defect Images:
      this.signOff.uploads.defects.forEach((imageObj, imageInd) => {
        formData.append(`uploads[defects][${imageInd}]`, imageObj)
      })

      if(this.computedHasTicket) {
        // formData.append('ticket[name]', this.ticket.name ?? '')
        // formData.append('ticket[email]', this.ticket.email ?? '')
        // formData.append('ticket[phone]', this.ticket.phone ?? '')
        // formData.append('ticket[spa]', this.ticket.spa ?? '')
        // formData.append('ticket[status]', this.ticket.status ?? '')
        formData.append('ticket[description]', this.ticket.description ?? '')
        formData.append('ticket[serial_number_image]', this.ticket.serial_number_image ?? '')
        formData.append('ticket[hot_tub_image]', this.ticket.hot_tub_image)
        formData.append('ticket[problem_file]', this.ticket.problem_file)
        formData.append('ticket[accept_disclaimer]', this.ticket.accept_disclaimer);
      }

      this.doSignOff({installationId: this.installation.id , installation: formData}).then(response => {
        if([ 200, 201 ].includes(response.status)) this.$router.push({
          name: 'dash.installations',
          params: {
            notifications: [{
              variant: 'success',
              title: `Installation Sign Off #${this.$route.params.id}`,
              subtitle: response.data.name,
              text: `The installation has been signed off successfully.`,
              timeout: 5000
            }]
          }
        })
      }).catch(err => {
        window.scrollTo(0,0)
      })
    },
    
    addImageTo(path) {
      this.signOff.uploads[path].push(null);
    },

    rmImageFrom(path, ind) {
      this.signOff.uploads[path].splice(ind,1);
    },

    returnFileUrl(path) {
      if(has(this.signOff.uploads, path) && get(this.signOff.uploads, path)) {
        if(has(get(this.signOff.uploads, path), 'url')) {
          return get(this.signOff.uploads, path)['url']
        }
        return URL.createObjectURL(get(this.signOff.uploads, path));
      }
      return null;
    },

    returnTicketFileUrl(fileKey) {
      if(this.ticket[fileKey]) {
        return URL.createObjectURL(this.ticket[fileKey]);
      }
    },

    submitNote: function() {
      if(this.installationNote) {
        this.doCreateInstallationNote({
          'installation': this.installation.id,
          'note': this.installationNote
        }).then(response => {
          this.installation.notes.push( response.data )
          this.installationNote = null
        })
      }
    }
  },
  watch: {
    single () {
      this.installation = this.single
    },
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }
}
</script>

<style>

</style>
