<template>
  <b-container fluid>
    <ResourceList
      :can-create="false" resource="installation" soft-deletes title="Installation Sign Offs" :has-buttons="false"
      :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination" :can-select-many="false"
      :search-query="$route.query.query" @delete-many="onDeleteMany" @paginate="onPaginate"
      @search="onSearch">

      <template #listItem="{ item }">
        <div class="d-flex flex-fill p-3">
          <div class="flex-column flex-fill">
            <p class="h5 mb-0"><router-link :to="{name: 'dash.installations.view', params: { id: (item.hasOwnProperty('order_item_id') ? item.order_item_id : item.id) }}">{{ item.order.customer.forename }} {{ item.order.customer.surname }}</router-link></p>
            <div class="d-flex flex-column mt-2">
              <small>{{ fullAddress(item.order.shipping_address, ' | ') }}</small>
            </div>
            <div class="d-flex flex-row mt-2">
              <b-badge pill variant="warning" class="mb-0 mr-3" v-if="item.electrics_required">Electrics Required</b-badge>
              <b-badge pill variant="warning" class="mb-0" v-if="item.crane_required">Crane Required</b-badge>
            </div>
          </div>
          <div class="d-flex flex-column">
            <p class="align-self-end mb-0" v-if="item.team_id">{{ item.team.deleted_at ? `${item.team.name} (Deleted)` : item.team.name }}</p>
            <p class="align-self-end mb-0" v-else>No Team Selected</p>
            <small class="align-self-end mb-0 mt-1">{{ installDate(item.install_at) }}</small>
            <small class="align-self-end mb-0 mt-1" v-if="item.supply_product && item.supply_product.storage_location_id">Storage Location: {{ item.supply_product.storage_location.name }}</small>
          </div>
        </div>
      </template>

    </ResourceList>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'
import TeamSearch from '../../components/TeamSearch'
import InstallationFullCalendar from '../../components/InstallationFullCalendar'

// Google Places
import GooglePlaces from '../../components/GooglePlaces'

window.selectMapDisplayItem = function(ind) {
  window.dispatchEvent(new CustomEvent('select-map-display-item', { detail: ind }))
}

export default {
  mixins: [currentUser, momentMixin],
  data () {
    return {
      startDate: null,
      endDate: null,
      filterName: null,
      installationDate: 'All',
      locale: null,
      team: { id : null },
    }
  },
  created () {
    this.fetchManySignedOff(this.$route.query)
  },
  components: {
    FriendlyDate,
    ResourceList,
    GooglePlaces,
    TeamSearch,
    InstallationFullCalendar
  },
  computed: {
    ...mapGetters('installations', ['data', 'isDeleting', 'isLoading', 'pagination']),
  },
  methods: {
    ...mapActions('installations', ['batchDelete', 'fetchManySignedOff']),
    async onDeleteMany ({ ids, modalId }) {
      await this.batchDelete({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchManySignedOff(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({
          name: this.$route.name,
          query: { ...this.$route.query, page }
        })
        await this.fetchManySignedOff({ 
          ...this.$route.query,
          page,
          filterName: this.filterName,
          installationDate: this.installationDate,
        })
      }
    },
    async doPaginate (page) {
        await this.fetchManySignedOff({ 
          ...this.$route.query,
          filterName: this.filterName,
          installationDate: this.installationDate,
        })
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchManySignedOff({ ...this.$route.query, query })
      }
    },
    installDate: function(date) {
      if (!date) return 'No Installation Date.';
      return 'Installation Date: ' + this.moment(date).format('DD/MM/YYYY');
    },

    mapDisplayItemAddress(item) {
      if(!item) return ''
      return this.fullAddress(item.order.shipping_address, ', ')
    },

    fullAddress(address, delimiter) {
      var returnAddress = ''
      if(address.address_line_1) returnAddress += `${address.address_line_1}${delimiter}`
      if(address.address_line_2) returnAddress += `${address.address_line_2}${delimiter}`
      if(address.city) returnAddress += `${address.city}${delimiter}`
      if(address.county) returnAddress += `${address.county}${delimiter}`
      if(address.postcode) returnAddress += `${address.postcode}`
      return returnAddress
    },
  },
}
</script>

<style lang="scss">

  .no-outline {
    outline: none !important;
    box-shadow: none !important;
  }

</style>
