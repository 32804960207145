<template>
  <b-container fluid>
    <b-row class="mb-4" v-if="!currentUser.has_teams">
      <b-col cols="12">
        <h1 class="font-weight-bold mb-1">Installations</h1>
      </b-col>
      <b-col cols="12">
        <b-card class="py-5 text-center mt-3">
          <p class="mb-4"><b-icon class="display-1 text-dark" icon="x"></b-icon></p>
          <p class="font-weight-normal h5 mb-0 text-dark">You must be in a team to view this page.</p>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mb-4" v-if="currentUser.has_teams">
      <b-col cols="12" md="6">
        <h1 class="font-weight-bold mb-1">Installations</h1>
        <p class="mb-0"><small>Showing booked installations for {{ moment(selectedDate).calendar(null, {
          sameDay: '[today]',
          nextDay: '[tomorrow]',
          nextWeek: 'dddd',
          sameElse: 'DD/MM/YYYY'
        }) }}</small></p>
      </b-col>
      <b-col class="d-flex flex-row justify-content-end" cols="12" md="6">
        <b-form-datepicker class="mt-4 mb-3 mr-3" @input="onDateChange" v-model="selectedDate" />
        <b-button class="align-self-end mt-1 mt-md-0 mb-3 text-nowrap" @click="selectingVan = !selectingVan" variant="secondary" v-b-popover.hover.bottom="'Select a van registration.'"><b-icon icon="truck"></b-icon></b-button>
        <b-button @click="calendarDisplay = !calendarDisplay" class="align-self-end ml-2 mt-1 mt-md-0 mb-3 text-nowrap" variant="primary" v-b-popover.hover.bottom="!calendarDisplay ? 'View Installations Calendar.' : 'View Installations List.'"><b-icon :icon="!calendarDisplay ? 'calendar-event' : 'card-list'"></b-icon></b-button>
      </b-col>
    </b-row>

    <ResourceList v-if="currentUser.has_teams"
      :can-create="false" :has-search="false" resource="installation" soft-deletes title=""
      :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination"
      :search-query="$route.query.query" @delete-many="onDeleteMany" @paginate="onPaginate"
      :collapsable-filters="false" :has-filters="true"
      :hide-list="calendarDisplay"
      @search="onSearch">

      <template #filters>
        <b-card no-body class="p-3" v-if="vanSelectionRequired || selectingVan">
          <b-row>
            <b-col cols="12">
              <h5 class="mb-3">Select your van registration plate and team:</h5>
              <b-form-group label="Team Select">
                <b-form-select class="mb-2" v-model="selectedTeam">
                  <b-form-select-option :value="null">Please select a team.</b-form-select-option>
                  <b-form-select-option :value="team.id" v-for="(team, teamInd) in currentUser.teams" :key="`team${teamInd}`">{{ team.name }}</b-form-select-option>
                </b-form-select>
              </b-form-group>
              <b-form-group label="Van Select">
                <b-form-select class="mb-2" v-model="selectedVanRegistration">
                  <b-form-select-option :value="null">Please select your team's registration plate.</b-form-select-option>
                  <b-form-select-option :value="van.id" v-for="(van, vanInd) in vanList" :key="`van${vanInd}`">{{ van.vehicle_registration }}</b-form-select-option>
                </b-form-select>
              </b-form-group>
              <b-button variant="primary" @click="doSetTeam()" class="btn btn-block">Save</b-button>
            </b-col>
          </b-row>
        </b-card>
      </template>

      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-0 text-primary"><router-link class="text-dark" :to="{name: 'dash.installations.sign-off', params: { id: item.id }}">{{ item.order.customer.forename }} {{ item.order.customer.surname }}</router-link></p>
            <p class="align-self-end mb-0 text-muted">{{ installDate(item.install_at) }}</p>
          </div>
          <div class="d-flex flex-row flex-fill">
            <b-button @click="copyToClipboard(item)" variant="link" v-b-popover.hover.bottom="recentlyCopiedAddressId != item.id ? 'Copy the address to the clipboard.' : 'Copied!'" class="p-0 text-dark"><small>{{ fullAddress(item.order.shipping_address, ' | ') }}</small></b-button>
            <input name="" :ref="`address${item.id}`" class="address-copy-from" tabindex="-1" aria-hidden="true" :value="fullAddress(item.order.shipping_address, ', ')">
          </div>
          <div class="d-flex flex-row flex-fill mt-2" v-if="!item.tracking_path">
            <b-button variant="secondary" size="sm" class="mb-0 mr-3" @click="sendTrackingLink(item)">Send Customer Tracking Link</b-button>
          </div>
          <div class="d-flex flex-row flex-fill mt-2" v-if="item.electrics_required || item.crane_required">
            <b-badge pill variant="warning" class="mb-0 mr-3" v-if="item.electrics_required">Electrics Required</b-badge>
            <b-badge pill variant="warning" class="mb-0" v-if="item.crane_required">Crane Required</b-badge>
          </div>
          <div v-if="item.crane_required && item.crane_time">
            <small class="text-muted">Crane Time: {{ item.crane_time }}</small>
          </div>
        </div>
      </template>

    </ResourceList>

    <InstallationFullCalendar
        v-if="calendarDisplay"
        :data="calendarData"
        :installationEvents="installationEvents"
        @getData="getCalendarData"
        @eventClick="eventClicked"
    ></InstallationFullCalendar>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'
import InstallationFullCalendar from '../../components/InstallationFullCalendar'

export default {
  mixins: [currentUser, momentMixin],
  data () {
    return {

      // Installer Van Select
      vanSelectionRequired: false,
      selectingVan: true,
      selectedVanRegistration: null,
      selectedTeam: null,
      recentlyCopiedAddressId: null,

      calendarDisplay: false,

      selectedDate: null,
      startDate: null,
      endDate: null,
    }
  },
  mounted() {
    this.vanSelectionRequired = this.currentUser.van_selection_required
    this.selectedTeam = this.currentUser.active_team
    // this.selectedDate = this.moment().toDate()
    if(!this.vanSelectionRequired) {
      this.selectingVan = false
    }
    if(this.selectedTeam) {
      this.fetchMany({ ...this.$route.query, 'selectedDate': this.selectedDate, team: this.selectedTeam.id })
    }
    this.fetchManyVans()
  },
  components: {
    FriendlyDate,
    ResourceList,
    InstallationFullCalendar
  },
  computed: {
    ...mapGetters('installations', ['data', 'isDeleting', 'isLoading', 'pagination', 'calendarData']),
    ...mapGetters('vans', { vanList: 'data' }),
    ...mapGetters('events', {
        'errors': 'errors',
        'installationEvents': 'data'
    }),
  },
  methods: {
    ...mapActions('installations', ['batchDelete', 'fetchMany', 'fetchManyCalendar']),
    ...mapActions('vans', {
      fetchManyVans: 'fetchMany',
      setTeam: 'setTeam'
    }),
    ...mapActions('events', ['createInstallEvent', 'deleteInstallEvent', 'fetchManyInstallationEvents']),

    copyToClipboard(item) {
      this.$refs[`address${item.id}`].select()
      document.execCommand('copy')
      this.recentlyCopiedAddressId = item.id
      setTimeout(() => {
        this.recentlyCopiedAddressId = null
      }, 1500)
    },
    
    eventClicked(payload) {
        switch(payload.event.extendedProps.type) {
            case 'installation':
                this.$router.push({name: 'dash.installations.sign-off', params: { id: payload.event._def.publicId }})
            break;
        }
    },

    fullAddress(address, delimiter) {
      var returnAddress = ''
      if(address.address_line_1) returnAddress += `${address.address_line_1}${delimiter}`
      if(address.address_line_2) returnAddress += `${address.address_line_2}${delimiter}`
      if(address.city) returnAddress += `${address.city}${delimiter}`
      if(address.county) returnAddress += `${address.county}${delimiter}`
      if(address.postcode) returnAddress += `${address.postcode}`
      return returnAddress
    },

    sendTrackingLink(item) {
      window.axios.get(`/installations/${item.id}/send-tracking-link`).then(response => {
        this.$root.$emit('card-notification', {
          variant: 'success',
          title: `Tracking Link Sent`,
          subtitle: `The trackign link has been sent to the customer.`,
          timeout: 2500
        })
        this.onSearch()
      })
    },

    async onDeleteMany ({ ids, modalId }) {
      await this.batchDelete({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchMany({ ...this.$route.query,  'team': this.selectedTeam })
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchMany({ ...this.$route.query, page, 'selectedDate': this.selectedDate, 'team': this.selectedTeam })
      }
    },
    async onDateChange(date) {
      await this.fetchMany({ ...this.$route.query, 'selectedDate': this.selectedDate, 'team': this.selectedTeam })
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchMany({ ...this.$route.query, query, 'selectedDate': this.selectedDate, 'team': this.selectedTeam })
      }
    },
    installDate: function(date) {
      if (!date) return 'No Installation Date.';
      return 'Installation Date: ' + this.moment(date).format('DD/MM/YYYY');
    },
    doSetTeam() {
      this.setTeam({ 'vanId': this.selectedVanRegistration, 'teamId': this.selectedTeam }).then(response => {
        this.vanSelectionRequired = false
        this.selectingVan = false
        this.fetchMany({ ...this.$route.query, 'page': null, 'selectedDate': this.selectedDate, 'team': this.selectedTeam })
      })
    },

    async getCalendarData (payload) {
        if(payload) {
            this.startDate = payload.start_date
            this.endDate = payload.end_date
        }
        this.fetchManyCalendar({
            'start_date': this.startDate,
            'end_date': this.endDate,
            'teams': [this.selectedTeam]
        })
        this.fetchManyInstallationEvents({
            'start_date': this.startDate,
            'end_date': this.endDate,
            'teams': [this.selectedTeam]
        })
    },
  }
}
</script>

<style>


  .address-copy-from {
    position: absolute;
    left: -9999px;
  }
</style>
