<template>
  <b-container fluid>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">Process Item Installation</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col cols="12">
                    <div class="d-flex flex-column">
                      <p class="h3 font-weight-bold mb-0">{{ installation.order.customer.forename }} {{ installation.order.customer.surname }}</p>
                      <small class="text-muted">{{ installation.order.customer.email }}</small>
                      <small class="mb-0 text-danger" v-if="installation.order.customer.deleted_at"><small>Customer Trashed.</small></small>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col cols="12">
                    <b-row>
                      <b-col cols="12" md="6">
                        <b-card>
                          <b-row>
                            <b-col cols="12">
                              <p class="h4 font-weight-bold mb-0">Shipping &amp; Installation Address</p>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12">
                              <div><hr></div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12">
                              <b-form-group label="Address Line 1" :invalid-feedback="validationInvalidFeedback(errors, 'shipping_address.address_line_1')">
                                <b-form-input :disabled="true" :state="validationState(errors, 'shipping_address.address_line_1')" v-model="installation.order.shipping_address.address_line_1" />
                              </b-form-group>
                            </b-col>
                            <b-col cols="12">
                              <b-form-group label="Address Line 2" :invalid-feedback="validationInvalidFeedback(errors, 'shipping_address.address_line_2')">
                                <b-form-input :disabled="true" :state="validationState(errors, 'shipping_address.address_line_2')" v-model="installation.order.shipping_address.address_line_2" />
                              </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                              <b-form-group label="City" :invalid-feedback="validationInvalidFeedback(errors, 'shipping_address.city')">
                                <b-form-input :disabled="true" :state="validationState(errors, 'shipping_address.city')" v-model="installation.order.shipping_address.city" />
                              </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                              <b-form-group label="County" :invalid-feedback="validationInvalidFeedback(errors, 'shipping_address.county')">
                                <b-form-input :disabled="true" :state="validationState(errors, 'shipping_address.county')" v-model="installation.order.shipping_address.county" />
                              </b-form-group>
                            </b-col>
                            <b-col cols="12">
                              <b-form-group label="Postcode" :invalid-feedback="validationInvalidFeedback(errors, 'shipping_address.postcode')">
                                <b-form-input :disabled="true" :state="validationState(errors, 'shipping_address.postcode')" v-model="installation.order.shipping_address.postcode" />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-col>
                      <b-col cols="12" md="6" class="mt-3 mt-md-0">
                        <b-card>
                          <b-row>
                            <b-col cols="12">
                              <p class="h4 font-weight-bold mb-0">Billing Address</p>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12">
                              <div><hr></div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12">
                              <b-form-group label="Address Line 1" :invalid-feedback="validationInvalidFeedback(errors, 'billing_address.address_line_1')">
                                <b-form-input :disabled="true" :state="validationState(errors, 'billing_address.address_line_1')" v-model="installation.order.billing_address.address_line_1" />
                              </b-form-group>
                            </b-col>
                            <b-col cols="12">
                              <b-form-group label="Address Line 2" :invalid-feedback="validationInvalidFeedback(errors, 'address.address_line_2')">
                                <b-form-input :disabled="true" :state="validationState(errors, 'billing_address.address_line_2')" v-model="installation.order.billing_address.address_line_2" />
                              </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                              <b-form-group label="City" :invalid-feedback="validationInvalidFeedback(errors, 'billing_address.city')">
                                <b-form-input :disabled="true" :state="validationState(errors, 'billing_address.city')" v-model="installation.order.billing_address.city" />
                              </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                              <b-form-group label="County" :invalid-feedback="validationInvalidFeedback(errors, 'billing_address.county')">
                                <b-form-input :disabled="true" :state="validationState(errors, 'billing_address.county')" v-model="installation.order.billing_address.county" />
                              </b-form-group>
                            </b-col>
                            <b-col cols="12">
                              <b-form-group label="Postcode" :invalid-feedback="validationInvalidFeedback(errors, 'billing_address.postcode')">
                                <b-form-input :disabled="true" :state="validationState(errors, 'billing_address.postcode')" v-model="installation.order.billing_address.postcode" />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12">
                    <div class="d-flex flex-column flex-fill justify-content-center">
                      <p class="h4 font-weight-bold mb-0 text-muted">Sign Request</p>
                    </div>
                    <div class="d-flex flex-row">
                        <b-button v-if="installation.order.sign_request.signed" :href="installation.order.sign_request.url" target="_blank">View Document</b-button>
                        <b-button variant="outline-danger" v-if="!installation.order.sign_request.signed">Awaiting Signature</b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" @click.prevent="toggleCollapse('video_route')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['video_route'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Video Route</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['video_route']">
                  <b-col cols="12" v-if="installation.video">
                    <b-row>
                      <b-col cols="12">
                        <hr class="mt-0">
                      </b-col>
                    </b-row>
                    <b-row v-if="installation.route_comments">
                      <b-col cols="12">
                        <b-form-group label="Route Comments">
                          <b-form-textarea v-model="installation.route_comments" disabled></b-form-textarea>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <video class="w-100" :src="installation.video.url" controls></video>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" v-if="!installation.video" class="p-4">
                    <b-row>
                      <b-col class="text-center" cols="12">
                        <b-card class="py-3 text-center">
                          <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                          <p class="font-weight-normal h5 mb-0 text-dark">The customer has not uploaded a video.</p>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <div class="d-flex flex-row justify-content-between">
                  <div class="d-flex flex-column justify-content-center">
                    <p class="h3 mb-0 font-weight-bold text-muted">Team</p>
                  </div>
                  <div class="d-flex flex-column flex-lg-row justify-content-center w-25">
                    <team-search :error-feed="errors.errors" :order-item="installation.id" :error-key="'team_id'" :wait-for-input="false" class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100" type="team" @input="teamUpdated" v-model="installation.team" />
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="9" @click.prevent="toggleCollapse('products_appointment')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['products_appointment'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Installation Item</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-2" v-if="!collapsed['products_appointment']">
                  <b-col cols="12" class="p-4">
                    <b-row>
                      <b-col cols="12">
                        <b-card class="mb-3">
                          <b-row>
                            <b-col cols="7">
                              <h4 class="mb-0">Product</h4>
                              <p class="mb-0">{{ installation.product.name }}</p>
                            </b-col>
                            <b-col cols="5" class="text-right">
                              <h5 class="mb-0">Supply: {{ installation.supply_product.supply.name }}</h5>
                              <p class="mb-0" v-if="installation.supply_product.storage_location_id">{{ installation.supply_product.storage_location.name }}</p>
                              <small>Expected Date: {{ installation.supply_product.expected_date ? moment(installation.supply_product.expected_date).format('DD/MM/YYYY') : moment(installation.supply_product.supply.expected_date).format('DD/MM/YYYY') }}</small>
                            </b-col>
                          </b-row>
                          <b-row v-if="installation.extras.length">
                            <b-col cols="12">
                              <div class="d-flex flex-column mt-3" v-if="installation.extras.length">
                                <b-card>
                                  <h6 class="mb-3">Extras:</h6>
                                  <b-table-simple small striped>
                                    <b-thead>
                                      <b-tr>
                                        <b-th>Name</b-th>
                                        <b-th class="text-right"><span class="mr-2">Amount</span></b-th>
                                      </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                      <b-tr v-for="(extra, extraInd) in installation.extras" :key="`extra${installation.id}-${extraInd}`">
                                        <b-td>{{ extra.name }}</b-td>
                                        <b-td class="text-right"><span class="mr-2">{{ extra.price | currencyFormat }}</span></b-td>
                                      </b-tr>
                                    </b-tbody>
                                  </b-table-simple>
                                </b-card>
                              </div>
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label="Install Required">
                          <b-form-radio v-model="installation.install_required" :value="1">Install is Required</b-form-radio>
                          <b-form-radio v-model="installation.install_required" :value="0">Install is not Required</b-form-radio>
                        </b-form-group>
                        <b-form-group label="Install At" v-if="installation.install_required">
                          <b-form-datepicker v-model="installation.install_at" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="6">
                        <b-form-group label="Crane Required" v-if="installation.install_required">
                          <b-form-radio v-model="installation.crane_required" :value="1">Crane is Required</b-form-radio>
                          <b-form-radio v-model="installation.crane_required" :value="0">Crane is not Required</b-form-radio>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Electrics Required" v-if="installation.install_required">
                          <b-form-radio v-model="installation.electrics_required" :value="1">Electrics is Required</b-form-radio>
                          <b-form-radio v-model="installation.electrics_required" :value="0">Electrics is not Required</b-form-radio>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row v-if="installation.crane_required">
                      <b-col cols="12">
                        <b-form-group label="Crane Time">
                          <b-form-input
                            v-model="installation.crane_time"
                            placeholder="HH:mm"
                            size="sm"></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="installation.order.additional_notes">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12">
                    <div class="d-flex flex-column flex-fill justify-content-center">
                      <p class="h4 font-weight-bold">Additional Notes</p>
                      <p class="mb-0 white-space-pre text-muted">{{ installation.order.additional_notes }}</p>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12" md="4" offset-md="8">
              <b-button block class="align-self-end mb-3" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import validation from '../../mixins/validation'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'
import TeamSearch from '../../components/TeamSearch'

export default {
  mixins: [validation,momentMixin],
  components: { TeamSearch },
  created () {
    var self = this;
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id)
    }
  },
  computed: {
    ...mapGetters('installations', ['errors', 'isLoading', 'isSaving', 'single']),
  },
  data () {
    return {
      collapsed: {
        installation_details: true,
        billing_address: true,
        shipping_address: true,
        products_appointment: false,
        video_route: false,
      },
      installation: {
        id: null,
        product_id: null,
        product: null,
        install_at: null,
        install_required: false,
        crane_required: false,
        crane_time: null,
        electrics_required: false,
        price: 0,
        team_id: null,
        team: { id: null }
      },
      financePackages: [],
    }
  },
  methods: {

    ...mapActions('installations', ['fetch', 'process']),

    toggleCollapse (name) {
      this.collapsed[name] = !this.collapsed[name]
    },

    teamUpdated() {
      this.installation.team_id = this.installation.team.id;
    },

    submit () {
      let data = {
        id: this.$route.params.id,
        install_required: this.installation.install_required,
        install_at: this.installation.install_at,
        crane_required: this.installation.crane_required,
        crane_time: this.installation.crane_time,
        electrics_required: this.installation.electrics_required,
        team_id: this.installation.team_id
      }

      this.process(data).then(response => {
        if([ 200, 201 ].includes(response.status)) this.$router.push({
          name: 'dash.installations.manage',
          params: {
            notifications: [{
              variant: 'success',
              title: `Installation #${response.data.id}`,
              subtitle: response.data.install_at ? this.moment(response.data.install_at).format('DD/MM/YYYY') : 'No Install Date',
              text: `The installation item has been processed successfully.`,
              timeout: 2500
            }]
          }
        })
      })
    },
  },
  watch: {
    single () {
      if(this.single.team == null) {
        this.single.team = { id: null }
      }
      this.installation = this.single
    },
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }
}
</script>

<style>

</style>
